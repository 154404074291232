import styled from 'styled-components';

export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  background: var(--color-white);
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.07), 0px 0.75px 1.13px rgba(0, 0, 0, 0.035);
  border-radius: 50%;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
`;

export const Image = styled.img`
  height: inherit;
`;

export const MerchantName = styled.span`
  font-weight: 300;
  font-size: 60px;
  color: var(--color-midnight-blue);
`;
