import { useMemo } from 'react';
import useOrganization from 'hooks/useOrganization';
import Title from 'components/Title';
import Avatar from 'components/Avatar';
import LinkButton from 'components//LinkButton';
import * as S from 'components/App/styles';
import { Loading } from 'components/Loading';
import styled from 'styled-components';
import { removeCharAtIndex } from 'utils/StringUtil';

const App = () => {
  const { name, organizationLinks, profileImageUrl, loading } = useOrganization();
  const slug = removeCharAtIndex(window.location.pathname, 0);

  const linkTree = useMemo(() => {
    const list = [
      {
        title: 'Cherry Payment Plans',
        link: `${process.env.PREACT_APP_PAY_WITHCHERRY_URL}/${slug}?utm_source=cherrytree`,
      },
      ...organizationLinks,
    ]?.map((treeItem) => (
      <li>
        <LinkButton label={treeItem.title} url={treeItem.link} />
      </li>
    ));

    return <ul>{list}</ul>;
  }, [organizationLinks]);

  return (
    <S.Root>
      {loading ? (
        <LoadingContainer>
          <Loading size={40} />
        </LoadingContainer>
      ) : (
        <>
          <Avatar merchantName={name} imgUrl={profileImageUrl} />
          <S.TitleWrapper>
            <Title value={name} />
          </S.TitleWrapper>
          {linkTree}
        </>
      )}
    </S.Root>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;

export default App;
