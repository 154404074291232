import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { navigateUserToMainWebsite } from 'utils/WindowUtil';

const httpLink = new HttpLink({
  uri: process.env.PREACT_APP_GQL_URL,
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
});

const checkTokenValidLink = new ApolloLink((operation: any, forward: any) => {
  return forward(operation).map((response: any) => {
    if (response?.data?.[operation.operationName]?.code === 'auth.token.invalid') {
      navigateUserToMainWebsite();
      return response;
    } else {
      return response;
    }
  });
});

const link = checkTokenValidLink.concat(httpLink);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, link]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
  name: 'cherry-tree',
  queryDeduplication: false,
});

export default client;
