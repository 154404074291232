import { getFirstLetter } from 'utils/StringUtil';
import * as S from 'components/Avatar/styles';

interface AvatarProps {
  imgUrl?: string;
  merchantName: string;
}

const Avatar = ({ imgUrl, merchantName }: AvatarProps) => {
  const firstLetter = getFirstLetter(merchantName);

  return (
    <S.Avatar>
      {imgUrl ? (
        <S.ImageWrapper>
          <S.Image alt={merchantName} src={imgUrl} />
        </S.ImageWrapper>
      ) : (
        <S.MerchantName>{firstLetter}</S.MerchantName>
      )}
    </S.Avatar>
  );
};

export default Avatar;
