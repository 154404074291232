import { useCallback, useEffect, useState, useMemo } from 'react';
import { removeCharAtIndex } from 'utils/StringUtil';
import { useApolloClient } from '@apollo/client';
import { GET_SLUG_LINKS } from 'graphql/queries/GetLinks';
import { navigateUserToMainWebsite } from 'utils/WindowUtil';
import { BackgroundType } from 'common/enums';

interface LinkList {
  id: number;
  title: string;
  link: string;
}

export interface OrganizationLink {
  id: number;
  organizationId: number;
  buttonColor: string;
  backgroundColor: string;
  organizationLinks: LinkList[];
  profileImageUrl: string;
  backgroundImageUrl: string;
  backgroundType: BackgroundType;
}

interface OrganizationDetails {
  name: string;
}

const useOrganization = () => {
  const client = useApolloClient();

  const slug = useMemo(() => {
    let slug = '';

    if (typeof window !== 'undefined') {
      slug = removeCharAtIndex(window.location.pathname, 0);

      if (slug?.length > 0) {
        return slug;
      } else {
        navigateUserToMainWebsite();
      }
    }

    return '';
  }, []);

  const [organizationDetails, setOrganizationDetails] = useState<OrganizationDetails>();
  const [organizationLinkConfig, setOrganizationLinkConfig] = useState<OrganizationLink>();
  const [loading, setLoading] = useState<boolean>(false);

  const getMerchantDetails = useCallback(async () => {
    try {
      setLoading(true);

      const {
        data: { getSlugLinkTreeLinks },
      } = await client.query({
        query: GET_SLUG_LINKS,
        variables: {
          input: {
            slug,
          },
        },
      });

      if (getSlugLinkTreeLinks?.organizationName && getSlugLinkTreeLinks.organizationLinks) {
        setOrganizationDetails({
          name: getSlugLinkTreeLinks.organizationName,
        });
        setOrganizationLinkConfig(getSlugLinkTreeLinks);

        setLoading(false);
      } else {
        navigateUserToMainWebsite();
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getMerchantDetails();
  }, [getMerchantDetails]);

  useEffect(() => {
    if (organizationLinkConfig?.buttonColor) {
      document.documentElement.style.setProperty('--button-color', organizationLinkConfig.buttonColor);
    }

    if (organizationLinkConfig?.backgroundType === BackgroundType.Image && organizationLinkConfig.backgroundImageUrl) {
      document.body.style.backgroundImage = `url(${organizationLinkConfig.backgroundImageUrl})`;
      document.body.style.backgroundRepeat = 'no-repeat';
      document.body.style.backgroundSize = 'cover';
      document.body.style.backgroundPosition = 'center';
    } else if (
      organizationLinkConfig?.backgroundType === BackgroundType.Color &&
      organizationLinkConfig.backgroundColor
    ) {
      document.documentElement.style.setProperty('--background-color', organizationLinkConfig.backgroundColor);
    }
  }, [organizationLinkConfig]);

  return {
    name: organizationDetails?.name || '',
    organizationLinks: organizationLinkConfig?.organizationLinks || [],
    profileImageUrl: organizationLinkConfig?.profileImageUrl,
    backgroundImageUrl: organizationLinkConfig?.backgroundImageUrl,
    backgroundType: organizationLinkConfig?.backgroundType,
    loading,
  };
};

export default useOrganization;
