import styled from 'styled-components';

export const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: var(--color-midnight-blue);
  margin: 0;
  padding: 0;
`;
