import * as S from 'components/LinkButton/styles';

interface LinkButtonProps {
  label: string;
  url: string;
}

const LinkButton = ({ label, url }: LinkButtonProps) => {
  return (
    <S.LinkButton href={url} target="_blank">
      <S.LinkButtonLabel>{label}</S.LinkButtonLabel>
    </S.LinkButton>
  );
};

export default LinkButton;
