import styled from 'styled-components';

export const LinkButton = styled.a`
  padding: 9px 16px;
  width: 100%;
  background: var(--button-color);
  border: 1px solid var(--button-color);
  border-radius: 24px;
  text-decoration: none;
`;

export const LinkButtonLabel = styled.span`
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: var(--color-white);
`;
