import { gql } from '@apollo/client/core';

export const GET_SLUG_LINKS = gql`
  query getSlugLinkTreeLinks($input: GetSlugLinkTreeLinksInput) {
    getSlugLinkTreeLinks(input: $input) {
      id
      organizationId
      buttonColor
      backgroundColor
      organizationLinks {
        id
        title
        link
      }
      organizationName
      profileImageUrl
      backgroundImageUrl
      backgroundType
      success
    }
  }
`;
