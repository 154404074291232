import { ApolloProvider } from '@apollo/client';
import 'styles/reset.css';
import 'styles';
import App from 'components/App';
import client from 'graphql/client';

const Index = () => {
  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
};

export default Index;
