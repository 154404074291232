import * as S from 'components/Title/styles';

interface TitleProps {
  value: string;
}

const Title = ({ value }: TitleProps) => {
  return <S.Title>{value}</S.Title>;
};

export default Title;
