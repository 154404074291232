import styled from 'styled-components';

export const Root = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 40px auto 0;
  padding: 0 16px;

  ul {
    width: 100%;
    margin: 0;
    padding: 0;

    li {
      display: block;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      * {
        display: block;
      }
    }
  }
`;

export const TitleWrapper = styled.div`
  margin: 16px 0 40px;
`;
