import styled from 'styled-components';

interface LoadingProps {
  size?: number;
}

export const Loading = styled.div<LoadingProps>`
  border: ${(props) => (props.size && props.size * 0.4) || 4}px solid #f3f3f3;
  border-top: ${(props) => (props.size && props.size * 0.4) || 4}px solid ${(props) => props.theme.main?.green};
  border-radius: 50%;
  width: ${(props) => props.size || 10}px;
  height: ${(props) => props.size || 10}px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
